import { useState } from 'react';
import { UPSERT_AD_CAMPAIGN_MUTATION } from '../../../../global/gql/mutations';
import { BrandedAssetData, CampaignsData, Refetch } from '../../../../global/interfaces';
import { useReneMutation, useReneQuery } from '../../../../hooks';
import { isObjEmpty } from '../../../../utils';
import { GET_BRANDED_ASSETS_QUERY } from '../../../../global/gql/queries';
import Icon from '../../../../components/Icon/Icon';
import Spinner from '../../../../components/spinner/spinner';
import LoadingFallback from '../../../../components/loading-fallback/loading-fallback';

import './branded-asset-catalog.scss';

const BrandedAssetCatalog = ({
  adCampaignId,
  brandedAssets,
  setCloseModal,
  refetch,
}: {
  adCampaignId: string;
  brandedAssets: BrandedAssetData[] | undefined;
  setCloseModal: () => void;
  refetch: Refetch<{ AdCampaigns: CampaignsData } | undefined>;
}) => {
  const [selectedAssets, setSelectedAssets] = useState<{ [key: string]: BrandedAssetData }>({});
  const { data, loading } = useReneQuery<{ BrandedAssets: { items: BrandedAssetData[] } }>(GET_BRANDED_ASSETS_QUERY);

  const [upsertCampaign, { loading: upsertLoading }] = useReneMutation(UPSERT_AD_CAMPAIGN_MUTATION, {
    onCompleted() {
      refetch();
      setCloseModal();
    },
  });
  const handleAddBrandedAsset = (asset: BrandedAssetData) => {
    setSelectedAssets((prev) => {
      const updatedAssets = { ...prev };
      if (updatedAssets[asset.brandedAssetId]) {
        delete updatedAssets[asset.brandedAssetId];
      } else {
        updatedAssets[asset.brandedAssetId] = asset;
      }
      return updatedAssets;
    });
  };
  const isSelected = !isObjEmpty(selectedAssets);

  const handleAddBrandedAssets = () => {
    const previousAssets = brandedAssets ? brandedAssets.map((asset) => asset.brandedAssetId) : undefined;
    let variables = {
      adCampaignId,
      brandedAssetIds: previousAssets
        ? [...previousAssets, ...Object.keys(selectedAssets)]
        : Object.keys(selectedAssets),
    };
    upsertCampaign({
      variables,
    });
  };
  return (
    <div className="branded-asset-catalog">
      <div className="branded-asset-catalog__heading">
        <h2>Branded Assets</h2>
        <button type="button" onClick={setCloseModal}>
          <Icon name="close" size={24} />
        </button>
      </div>
      <div className={`branded-asset-catalog__main`}>
        {!loading ? (
          data?.BrandedAssets.items?.map((asset: BrandedAssetData) => (
            <button
              key={asset.brandedAssetId}
              className="branded-asset-catalog__main_asset"
              onClick={() => handleAddBrandedAsset(asset)}
            >
              <div className="checkbox-container">
                <div className={`checkbox checkbox${!!selectedAssets[asset.brandedAssetId] && '--checked'}`}>
                  {!!selectedAssets[asset.brandedAssetId] ? <Icon name="checkmark" size={8} /> : null}
                </div>
              </div>
              <img src={asset.image.url} alt="branded asset" />
              <div className="branded-asset-catalog__main_asset_info">
                <p className="branded-asset-catalog__main_asset_info_brand">{asset.brand.name}</p>
                <p className="branded-asset-catalog__main_asset_info_name">{asset.name}</p>
                <p className="branded-asset-catalog__main_asset_info_impressions">
                  Total: <span>{asset.stats.impressions}</span> <Icon name="eye" size={16} />
                </p>
              </div>
            </button>
          ))
        ) : (
          <LoadingFallback />
        )}
        {isSelected && (
          <div className="branded-asset-catalog__main_add">
            <p>{Object.keys(selectedAssets).length} selected</p>
            <button className="secondary-btn" onClick={handleAddBrandedAssets} disabled={upsertLoading}>
              {upsertLoading ? <Spinner size="sm" /> : 'Add'}
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default BrandedAssetCatalog;
