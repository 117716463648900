import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { UserRole } from '../../../global/consts';
import { ADD_CREDITS_TO_ORG } from '../../../global/gql/mutations';
import { GET_USER_QUERY } from '../../../global/gql/queries';
import { UserData } from '../../../global/interfaces';
import { useUpdatePopUpContext } from '../../../context/update-message-context';
import { useReneMutation, useReneQuery } from '../../../hooks';
import Input from '../../../components/input/input';
import Spinner from '../../../components/spinner/spinner';
import LoadingFallback from '../../../components/loading-fallback/loading-fallback';

import './admin-dashboard.scss';

const AdminDashboard = () => {
  const navigate = useNavigate();
  const { showUpdatePopUpMessage } = useUpdatePopUpContext();

  const [credits, setCredits] = useState({
    amount: '',
    account: '',
  });

  const { data: user } = useReneQuery<{ User: UserData } | undefined>(GET_USER_QUERY);

  const [addCredits, { loading: loadingCredits }] = useReneMutation(ADD_CREDITS_TO_ORG, {
    onCompleted: () => {
      setCredits({
        amount: '',
        account: '',
      });
      showUpdatePopUpMessage('Credits allocated');
    },
  });
  const isReneUser = user?.User?.email?.match('@reneverse.io');

  if (user && isReneUser && user.User.role !== UserRole.ADMIN && user.User.role !== UserRole.OWNER) {
    navigate(-1);
    return null;
  }

  const handleAddCredits = () => {
    addCredits({
      variables: {
        orgId: credits.account,
        allocatedCredits: credits.amount,
      },
    });
  };

  if (user)
    return (
      <div className="admin">
        <div className="admin-dashboard">
          <div className="admin-dashboard__credit">
            <h2>Select account to add credits</h2>
            <div>
              <Input
                className="admin-dashboard__credit_org"
                value={credits.account}
                handleInput={(e) => setCredits((prev) => ({ ...prev, account: e.target.value }))}
                placeholder="Org Id"
              />
              <Input
                type="number"
                value={credits.amount}
                handleInput={(e) => setCredits((prev) => ({ ...prev, amount: e.target.value }))}
                placeholder="Add credits"
              />
              <button
                onClick={handleAddCredits}
                className="primary-btn"
                disabled={!credits.account || !credits.amount || loadingCredits}
              >
                {loadingCredits ? <Spinner size="sm" /> : 'Add'}
              </button>
            </div>
          </div>
        </div>
      </div>
    );

  return <LoadingFallback />;
};

export default AdminDashboard;
