import { GET_GAMES_TRANSACTIONS_QUERY } from '../../global/gql/queries';
import { GameData, GamesData } from '../../global/interfaces';
import { useReneQuery } from '../../hooks';
import LoadingFallback from '../loading-fallback/loading-fallback';
import Wallet from '../wallet/wallet';
import placeholder from '../../global/images/avatar.webp';

import './game-wallet.scss';

const GameWallet = ({ game }: { game: GameData }) => {
  const { data } = useReneQuery<{ Games: GamesData } | undefined>(GET_GAMES_TRANSACTIONS_QUERY, {
    variables: { gameId: game.gameId, walletOnly: true },
  });

  if (!data) {
    return <LoadingFallback />;
  }

  return (
    <div className="game-wallet">
      <div className="game-wallet__game">
        <div>
          <img src={data?.Games.items[0]?.image?.url || placeholder} alt="game" />
        </div>
        <div>
          <h1>{data?.Games.items[0]?.name}</h1>
          <h2>{data?.Games.items[0]?.description}</h2>
          <div>
            <p>
              {`${data?.Games.items?.[0].stats.walletAssets}
                ${parseInt(data?.Games.items?.[0].stats.walletAssets) > 1 ? 'assets' : 'asset'}`}
            </p>
          </div>
        </div>
      </div>
      <Wallet
        gameId={game.gameId}
        assets={data?.Games.items[0]?.assets?.items}
        walletAddress={data?.Games.items[0]?.walletAddress}
        transactions={data?.Games.items[0]?.transactions?.items}
      />
    </div>
  );
};

export default GameWallet;
