import { useState } from 'react';
import {
  CampaignData,
  Dispatcher,
  Event,
  OrganizationData,
  ChainsData,
  ChainOption,
  CampaignsData,
  Refetch,
} from '../../../global/interfaces';
import { useLocation, useNavigate } from 'react-router-dom';
import { differenceInDays, isPast } from 'date-fns';
import { GET_AD_CAMPAIGN_CPM, GET_ORG_QUERY, GET_CHAINS } from '../../../global/gql/queries';
import { CAMPAIGNS, ORG } from '../../../global/routes';
import { countries /*, regions */ } from './countries';
import { validations } from './validations';
import { formatDate, createCampaignChainOptions, formatNumber } from '../../../utils';
import { CampaignAssetType, CampaignGoal, Gender } from '../../../global/consts';
import { useReneMutation, useReneQuery, useValidation } from '../../../hooks';
import { UPSERT_AD_CAMPAIGN_MUTATION } from '../../../global/gql/mutations';

import Icon from '../../Icon/Icon';
import Input from '../../input/input';
import Select from '../../select/select';
import Modal from '../modal';
import Spinner from '../../spinner/spinner';
import Checkbox from '../../checkbox/checkbox';
import Textarea from '../../textarea/textarea';
import TwoHandleSlider from '../../two-handle-slider/two-handle-slider';
import TopUpCreditModal from '../top-up-credit-modal/top-up-credit-modal';
import LoadingFallback from '../../loading-fallback/loading-fallback';

import bannerTypeImg from '../../../global/images/banner.svg';
import brandedTypeImg from '../../../global/images/branded-asset.svg';

import './campaign-modal.scss';

interface Variables {
  adCampaignId?: string;
  name: string;
  description: string;
  file?: File | undefined;
  ageFrom: number;
  ageTo: number;
  gender: Gender[] | [];
  startDate: string;
  endDate: string;
  targetMarket?: string;
  updatedAt?: string;
  totalImpressions?: number;
  campaignImage?: {
    extension: string;
    fileId?: string;
  };
  chainId: number;
  web3Only: boolean;
  assetType: CampaignAssetType;
}

const selectOptions = {
  campaignGoal: {
    // [CampaignGoal.PRODUCT_PLACEMENT]: 'Product placement',
    [CampaignGoal.BRAND_AWARENESS]: 'Brand Awareness',
  },
  targetCountries: countries,
};

const CampaignDataModal = ({
  type,
  isEdit = false,
  previousCampaign,
  refetch,
  setCloseModal,
}: {
  type?: string;
  isEdit?: boolean;
  refetch: Refetch<{ Organization: OrganizationData } | { AdCampaigns: CampaignsData } | undefined>;
  previousCampaign?: CampaignData;
  setCloseModal: () => void;
}) => {
  const location = useLocation();
  const navigate = useNavigate();
  const { errors, isFieldInvalid, isFormInvalid } = useValidation(validations);
  const [loading, setLoading] = useState<boolean>(false);
  const [previousData, setPreviousData] = useState<CampaignData>();
  const [isTopupOpen, setIsTopupOpen] = useState(false);
  const assetType: CampaignAssetType = type === 'banner' ? CampaignAssetType.BANNER : CampaignAssetType.BRANDED_ASSET;

  const [form, setForm] = useState<{
    name: string;
    description: string;
    ageFrom: number;
    ageTo: number;
    gender: Gender[] | [];
    campaignGoal: string;
    campaignImage: string;
    startDate: string;
    endDate: string;
    targetMarket: string;
    totalImpressions: number;
    chainId: number; // The actual chain ID for saving to the database
    blockchain: string;
    web3Only: boolean;
    assetType: CampaignAssetType;
  }>({
    name: '',
    description: '',
    ageFrom: 25,
    ageTo: 75,
    gender: [],
    campaignGoal: '',
    startDate: '',
    endDate: '',
    targetMarket: '',
    campaignImage: '',
    totalImpressions: 0,
    chainId: 0,
    blockchain: 'None',
    web3Only: false,
    assetType: assetType,
  });

  const { data: orgData, refetch: refetchOrg } = useReneQuery<{ Organization: OrganizationData }>(GET_ORG_QUERY);
  const { data: campaignCpm } = useReneQuery<{ AdCampaignCPM: { cpm: number } }>(GET_AD_CAMPAIGN_CPM);
  const { data: chains } = useReneQuery<{ Chains: ChainsData }>(GET_CHAINS);

  const chainOptions = createCampaignChainOptions(chains?.Chains);

  if (chainOptions.length > 1 && previousCampaign && previousCampaign !== previousData) {
    setPreviousData(previousCampaign);
    setForm({
      name: previousCampaign.name,
      description: previousCampaign.description,
      startDate: formatDate(previousCampaign.startDate),
      endDate: formatDate(previousCampaign.endDate),
      campaignGoal: previousCampaign.campaignGoal,
      ageFrom: previousCampaign.ageFrom,
      ageTo: previousCampaign.ageTo,
      gender: previousCampaign.gender,
      targetMarket: previousCampaign.targetMarket,
      campaignImage: previousCampaign?.campaignImage?.url,
      totalImpressions: previousCampaign.totalImpressions,
      chainId: previousCampaign.chainId,
      blockchain: chainOptions.find((option) => option.chainId === previousCampaign.chainId)?.name || 'None',
      web3Only: previousCampaign.web3Only,
      assetType: previousCampaign.assetType,
    });
  }

  const [upsertCampaign] = useReneMutation(UPSERT_AD_CAMPAIGN_MUTATION, {
    onCompleted(data: { UpsertAdCampaign: CampaignData }) {
      handleFinishUpsert(data.UpsertAdCampaign.adCampaignId);
    },
  });

  const handleFinishUpsert = (campaignId: string) => {
    setLoading(false);
    setCloseModal();
    refetch();

    if (location.pathname === `/${ORG}`) {
      navigate(`${CAMPAIGNS}/${campaignId}`);
    }
  };

  const calculateImpressionsPrice = (impressions: number, cpm: number) => (impressions / 1000) * cpm;
  const dateDayRange = differenceInDays(new Date(form.endDate), new Date(form.startDate));
  const dailyCap = dateDayRange && form.totalImpressions && form.totalImpressions / dateDayRange;

  const selectedImpressionsPrice =
    form.totalImpressions &&
    campaignCpm?.AdCampaignCPM?.cpm &&
    calculateImpressionsPrice(form.totalImpressions, campaignCpm.AdCampaignCPM.cpm);

  const handleFormChange = (e: Event['Input'] | Event['TextArea']) => {
    let value = e.target.value;
    if (isEdit && e.target.name === 'startDate') {
      if (isFieldInvalid('editStartDate', e.target.value)) return;
    }
    if (e.target.name === 'totalImpressions') {
      return setForm((prev) => ({ ...prev, [e.target.name]: parseInt(value) }));
    }
    setForm((prev) => ({ ...prev, [e.target.name]: value }));
  };

  const handleAddingFixedAmount = (e: Event['Button']) => {
    const value = e.currentTarget.name;
    return setForm((prev) => ({ ...prev, totalImpressions: parseInt(value) }));
  };

  const handleCheckbox = (field: 'gender', value: Gender) => {
    if (form[field].some((key) => key === value)) {
      return setForm((prev) => ({
        ...prev,
        [field]: [...(form[field] as Array<Gender>).filter((key) => key !== value)],
      }));
    }
    setForm((prev) => ({ ...prev, [field]: [...form[field], value] }));
  };

  const addLocationsValue = (value: string) => {
    setForm((prev) => ({ ...prev, targetMarket: value }));
  };

  const handleSliderChange = (min: number, max: number) => {
    setForm((prev) => ({ ...prev, ageFrom: min, ageTo: max }));
  };

  const handleChainSelect = (selectedChain: ChainOption | undefined) => {
    setForm((prev) => ({
      ...prev,
      blockchain: selectedChain ? selectedChain.name : 'None',
      chainId: selectedChain ? selectedChain.chainId : 0,
    }));
  };

  const handleAddingNewCampaign = () => {
    if (isFormInvalid({ ...form, isEdit, selectedImpressionsPrice, balance: orgData?.Organization.balance?.balance }))
      return;
    let variables: Variables = {
      name: form.name.trim(),
      description: form.description.trim(),
      ageFrom: form.ageFrom,
      ageTo: form.ageTo,
      gender: form.gender,
      startDate: form.startDate,
      endDate: form.endDate,
      updatedAt: previousCampaign?.updatedAt,
      chainId: form.chainId,
      web3Only: form.web3Only,
      assetType: form.assetType,
    };

    variables = !isEdit && form.targetMarket ? { ...variables, targetMarket: form.targetMarket } : variables;
    variables = previousCampaign?.adCampaignId
      ? { ...variables, adCampaignId: previousCampaign.adCampaignId }
      : variables;
    variables =
      !isEdit && form.totalImpressions ? { ...variables, totalImpressions: form.totalImpressions } : variables;
    variables =
      form.blockchain === 'None'
        ? { ...variables, chainId: 0 }
        : {
            ...variables,
            chainId: chainOptions.find((option) => option.name === form.blockchain)?.chainId ?? 0,
          };

    setLoading(true);
    upsertCampaign({ variables });
  };

  return (
    <div className="campaign-modal">
      <div className="campaign-modal__heading">
        <div>
          <img src={type === 'banner' ? bannerTypeImg : brandedTypeImg} alt="banner" />
          <h2>{isEdit ? 'Edit' : 'New'} Campaign</h2>
        </div>
        <button type="button" onClick={setCloseModal}>
          <Icon name="close" size={24} />
        </button>
      </div>
      <p className="campaign-modal__campaign-type">{type === 'banner' ? 'Ad Banner' : 'Branded Asset'}</p>
      <Input
        label="Name"
        name="name"
        placeholder="Enter Campaign name"
        handleInput={handleFormChange}
        value={form.name}
        errorMessage={errors?.name}
      />
      <Textarea
        label="Description"
        name="description"
        value={form.description}
        handleInput={handleFormChange}
        placeholder="Enter Campaign description"
        showCounter
        maxLength={100}
        errorMessage={errors?.description}
      />
      <div className="campaign-modal__market">
        <Select
          label="Target market"
          value={form.targetMarket}
          placeholder="Select market"
          options={selectOptions.targetCountries}
          changeHandler={(value) => addLocationsValue(value)}
          showSelectedValueFn={(value) => value}
          errorMsg={errors?.targetMarket}
          disabled={isEdit}
          showError
        />
        <div className="campaign-modal__market_type">
          <label>Target type</label>
          <Checkbox value={form.web3Only} setValue={() => setForm((prev) => ({ ...prev, web3Only: !form.web3Only }))}>
            Web3 games only
          </Checkbox>
        </div>
      </div>

      <div className="campaign-modal__demographics">
        <label>Audience</label>
        <div className="campaign-modal__demographics_gender">
          <label>Gender</label>
          <div>
            <Checkbox
              value={form.gender.some((key) => key === Gender.MALE)}
              setValue={() => handleCheckbox('gender', Gender.MALE)}
            >
              Male
            </Checkbox>
            <Checkbox
              value={form.gender.some((key) => key === Gender.FEMALE)}
              setValue={() => handleCheckbox('gender', Gender.FEMALE)}
            >
              Female
            </Checkbox>
            <Checkbox
              value={form.gender.some((key) => key === Gender.OTHER)}
              setValue={() => handleCheckbox('gender', Gender.OTHER)}
            >
              Other
            </Checkbox>
          </div>
        </div>
        <p className="error-msg">{errors?.gender}</p>
        <div className="campaign-modal__demographics_age">
          <label>Age</label>
          <div>
            <TwoHandleSlider
              min={0}
              max={100}
              minValue={form.ageFrom}
              maxValue={form.ageTo}
              onChange={handleSliderChange}
            />
          </div>
        </div>
        <p className="error-msg">{errors?.age}</p>
      </div>
      <div className="campaign-modal__date">
        <Input
          label="Start Date"
          type="date"
          name="startDate"
          value={form.startDate}
          handleInput={handleFormChange}
          errorMessage={errors?.startDate || errors?.editStartDate}
          disabled={isEdit && isPast(new Date(form.startDate))}
        />
        <Input
          label="End Date"
          type="date"
          name="endDate"
          value={form.endDate}
          handleInput={handleFormChange}
          errorMessage={errors?.endDate}
        />
        <p>NOTE: The campaign will not start unless at least one ad is added to the campaign.</p>
      </div>
      <div className="campaign-modal__impressions">
        <div className="campaign-modal__impressions_heading">
          <label>Impressions</label>
          <div>
            {errors?.noFunds ? (
              <p className="campaign-modal__impressions_heading_error">{errors?.noFunds}</p>
            ) : (
              <p>Balance: ${orgData?.Organization.balance?.balance || 0}</p>
            )}
            <button className="secondary-btn" onClick={() => setIsTopupOpen(true)}>
              Top-Up
            </button>
          </div>
        </div>
        <div
          className={`campaign-modal__impressions_input ${
            errors?.noFunds || errors?.totalImpressions ? 'campaign-modal__impressions_input--no-funds' : ''
          }
          ${isEdit ? 'campaign-modal__impressions_input--disabled' : ''}`}
        >
          <div className="campaign-modal__impressions_input_input">
            <Input
              name="totalImpressions"
              value={form.totalImpressions}
              handleInput={handleFormChange}
              placeholder="Input value"
              type="number"
              hideErrorMessage
              disabled={isEdit}
            />
            {!isNaN(dailyCap) && form.totalImpressions ? <p>Daily cap: {formatNumber(dailyCap, 0)}</p> : null}
          </div>
          <div className="campaign-modal__impressions_input_amount">
            <p>=</p>
            <p>${selectedImpressionsPrice || 0}</p>
          </div>
        </div>
        <div className="campaign-modal__impressions_buttons">
          {[10000, 50000, 100000, 1000000].map((btn, i) => {
            return (
              <button
                key={i}
                className={form.totalImpressions === btn ? 'campaign-modal__impressions_buttons--selected' : ''}
                name={btn.toString()}
                onClick={handleAddingFixedAmount}
                disabled={isEdit}
              >
                <p>{formatNumber(btn, 0)}</p>
                <p>${calculateImpressionsPrice(btn, campaignCpm?.AdCampaignCPM.cpm as number)}.00</p>
              </button>
            );
          })}
        </div>
        <div className="campaign-modal__blockchain">
          {chainOptions ? (
            <Select
              label="Blockchain"
              value={form.blockchain}
              options={chainOptions.map((option) => option.name)}
              showListValueFn={(item) => item}
              changeHandler={(index) => {
                handleChainSelect(chainOptions[index]);
              }}
              errorMsg={errors?.blockchain}
            />
          ) : (
            <LoadingFallback />
          )}
        </div>
        <p className="campaign-modal__impressions_des">
          When you create a campaign, we reserve the amount from your credit balance. It is only charged at the end of
          the campaign. If you delete campaign, the amount is refunded to your credit balance.
        </p>
      </div>
      <div className="campaign-modal__asset_actions">
        <button type="button" className="secondary-btn" onClick={setCloseModal} disabled={loading}>
          Cancel
        </button>
        <button type="button" className="primary-btn" onClick={handleAddingNewCampaign}>
          {loading ? <Spinner size="sm" /> : isEdit ? 'Edit' : 'Create'}
        </button>
      </div>
      <Modal isOpen={isTopupOpen}>
        <TopUpCreditModal
          balance={orgData?.Organization.balance?.balance as string}
          setIsModalOpen={setIsTopupOpen}
          redirectPage=""
          refetch={refetchOrg}
        />
      </Modal>
    </div>
  );
};

const CampaignTypeModal = ({
  type,
  setType,
  setCloseModal,
  setCreateCampaign,
}: {
  type: string;
  setType: Dispatcher<string>;
  setCloseModal: () => void;
  setCreateCampaign: Dispatcher<boolean>;
}) => {
  return (
    <div>
      <div className="campaign-modal__heading">
        <h2>New Campaign</h2>
        <button type="button" onClick={setCloseModal}>
          <Icon name="close" size={24} />
        </button>
      </div>
      <div className="campaign-modal__type">
        <button className={type === 'banner' ? 'campaign-modal__type_set' : ''} onClick={() => setType('banner')}>
          <img src={bannerTypeImg} alt="banner type" />
          <h3>Ad Banner</h3>
          <p>Static image ad</p>
        </button>
        <button className={type === 'branded' ? 'campaign-modal__type_set' : ''} onClick={() => setType('branded')}>
          <img src={brandedTypeImg} alt="branded asset type" />
          <h3>Branded Asset</h3>
          <p>In-game object</p>
        </button>
      </div>
      <div className="campaign-modal__asset_actions">
        <button type="button" className="secondary-btn" onClick={setCloseModal}>
          Cancel
        </button>
        <button disabled={!type} type="button" className="primary-btn" onClick={() => setCreateCampaign(true)}>
          Continue
        </button>
      </div>
    </div>
  );
};

const CampaignModal = ({
  isEdit = false,
  previousCampaign,
  refetch,
  setCloseModal,
}: {
  isEdit?: boolean;
  previousCampaign?: CampaignData;
  refetch: Refetch<{ Organization: OrganizationData } | { AdCampaigns: CampaignsData } | undefined>;
  setCloseModal: () => void;
}) => {
  const [type, setType] = useState('');
  const [createCampaign, setCreateCampaign] = useState(false);

  return createCampaign || isEdit ? (
    <CampaignDataModal
      refetch={refetch}
      type={type}
      isEdit={isEdit}
      previousCampaign={previousCampaign}
      setCloseModal={setCloseModal}
    />
  ) : (
    <CampaignTypeModal
      type={type}
      setType={setType}
      setCloseModal={setCloseModal}
      setCreateCampaign={setCreateCampaign}
    />
  );
};

export default CampaignModal;
