import { useState, useCallback, useMemo } from 'react';
import { CampaignAssetType, CampaignStatus } from '../../../global/consts';
import { CampaignData, CampaignsData, Dispatcher, Refetch } from '../../../global/interfaces';
import { useReneMutation, useValidation } from '../../../hooks';
import { endOfDay, format, isPast, isToday, parseISO } from 'date-fns';
import { DatePicker } from '../../calendar/calendar';
import { validations } from './validations';
import { UPSERT_AD_CAMPAIGN_MUTATION } from '../../../global/gql/mutations';
import Icon from '../../Icon/Icon';
import Modal from '../../modal/modal';
import Select from '../../select/select';
import Spinner from '../../spinner/spinner';

import typeBanner from '../../../global/images/banner.svg';
import typeBranded from '../../../global/images/branded-asset.svg';

import './campaign-banner.scss';

interface CampaignStats {
  impressions: string;
  interactions: string;
  cost: string;
}

const CampaignBanner = ({
  status,
  campaignType,
  adCampaignId,
  name,
  description,
  stats,
  startDate,
  endDate,
  brandName,
  allowEditCampaign,
  setIsOpenCampaignModal,
  refetch,
}: {
  status: CampaignData['status'] | undefined;
  campaignType: CampaignAssetType | undefined;
  adCampaignId: string | undefined;
  name: string | undefined;
  description: string | undefined;
  stats: CampaignStats | undefined;
  startDate: string | undefined;
  endDate: string | undefined;
  brandName: string | undefined;
  campaignImage: string | undefined;
  allowEditCampaign: boolean;
  setIsOpenCampaignModal: () => void;
  refetch: Refetch<{ AdCampaigns: CampaignsData } | undefined>;
}) => {
  const { errors, isFieldInvalid } = useValidation(validations);

  const campaignStartDate = startDate && new Date(startDate);
  const campaignEndDate = endDate && new Date(endDate);
  const endDateParse = parseISO(endDate as string);
  const endOfEndDate = endOfDay(endDateParse);
  const [isReactivateModal, setIsReactivateModal] = useState(false);
  const [upsertCampaign, { loading }] = useReneMutation(UPSERT_AD_CAMPAIGN_MUTATION, {
    onCompleted: () => {
      refetch();
      setIsReactivateModal(false);
    },
  });

  const campaignOptions: { key: number; label: string; func: Dispatcher<boolean> }[] = useMemo(
    () => [
      {
        key: 0,
        label: 'Edit',
        icon: <Icon name="edit" />,
        func: setIsOpenCampaignModal,
      },
    ],
    [setIsOpenCampaignModal],
  );

  const handleBrandedAssetAction = useCallback(
    (index: number) => {
      campaignOptions[index].func(true);
    },
    [campaignOptions],
  );

  const handleReactivate = (date: Date) => {
    if (!adCampaignId || !date || isFieldInvalid('endDate', { endDate: date, startDate })) return;
    upsertCampaign({
      variables: {
        adCampaignId,
        endDate: date,
      },
    });
  };

  const actionButton = () => {
    let label = '';
    let className = 'campaign-banner__action';
    let action = () => {};
    if (status === 'DRAFT') {
      label = 'Schedule';
    } else if (status === 'IN_PROGRESS') {
      label = 'Stop Campaign';
      className = className + ' campaign-banner__action--stop';
    } else if (status === 'SCHEDULED') {
      label = 'Unschedule';
      className = className + ' campaign-banner__action--scheduled';
    }
    return (
      <button className={className} onClick={action} disabled={status === 'DRAFT'}>
        {label}
      </button>
    );
  };

  return (
    <div className="campaign-banner">
      {allowEditCampaign && endDate && !isPast(endOfEndDate) && !isToday(endOfEndDate) && (
        <Select
          className="campaign-banner__dots"
          value=""
          placeholder=""
          options={campaignOptions}
          changeHandler={handleBrandedAssetAction}
          showListValueFn={(option) => {
            return (
              <>
                {option.icon} {option.label}
              </>
            );
          }}
        >
          <Icon name="dots" size={44} />
        </Select>
      )}
      <div className="campaign-banner__image">
        {campaignType && <img src={campaignType === 'BANNER' ? typeBanner : typeBranded} alt="game" />}{' '}
      </div>
      <div className="campaign-banner__title">
        <div>
          <h2>{brandName}</h2>
          <h1>{name}</h1>
          {status && (
            <div className="campaign-banner__date">
              <p className={status}>{CampaignStatus[status]}</p>
              <p>{campaignStartDate && format(new Date(startDate), 'MMM dd')}.</p> -
              <p>{campaignEndDate && format(new Date(endDate), 'MMM dd, yyyy')}.</p>
            </div>
          )}
        </div>
      </div>
      <div className="campaign-banner__stats">
        {campaignType && <p>{campaignType === 'BANNER' ? 'Ad Banner' : 'Branded Asset'}</p>}
        <p>
          <span>{stats?.impressions}</span> impressions
        </p>
        <p>
          $ <span>{stats?.cost}</span>
        </p>
      </div>
      <div className="campaign-banner__description">
        <p>{description}</p>
      </div>
      {actionButton()}

      <Modal isOpen={isReactivateModal}>
        <ReactivateCampaignModal
          error={errors?.endDate}
          loading={loading}
          handleReactivate={handleReactivate}
          setIsReactivateModal={setIsReactivateModal}
        />
      </Modal>
    </div>
  );
};

export default CampaignBanner;

const ReactivateCampaignModal = ({
  loading,
  error,
  handleReactivate,
  setIsReactivateModal,
}: {
  loading: boolean;
  error: string;
  handleReactivate: (date: Date) => void;
  setIsReactivateModal: Dispatcher<boolean>;
}) => {
  const [date, setDate] = useState<Date>(new Date());

  const closeModal = () => {
    setIsReactivateModal(false);
  };

  return (
    <div className="reactivate-campaign-modal">
      <div className="reactivate-campaign-modal__heading">
        <h2>Reactivate Campaign</h2>
        <button type="button" onClick={closeModal}>
          <Icon name="close" size={24} />
        </button>
      </div>
      <div className="reactivate-campaign-modal__date">
        <p>New end date</p>
        <DatePicker selectedDay={date} setSelectedDay={setDate} />
        <p className="reactivate-campaign-modal__date_error">{error}</p>
      </div>
      <div className="reactivate-campaign-modal__actions">
        <button type="button" className="secondary-btn" onClick={closeModal} disabled={loading}>
          Cancel
        </button>
        <button type="button" className="primary-btn" onClick={() => handleReactivate(date)}>
          {loading ? <Spinner size="sm" /> : 'Create'}
        </button>
      </div>
    </div>
  );
};
